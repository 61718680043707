<template>
  <div>
    <v-dialog class="square" v-model='show' persistent :max-width="confirmDialog && event && event.thankYouVideo ? '1500px' : '800px'">
      <div class="dialog-inner">
        <v-icon class="close" large @click='closeConfirmed()'>mdi-close</v-icon>
        <div v-if="!confirmDialog">
          <v-form ref="form">
            <v-card>
              <v-card-title class="mb-5">Your Order
              </v-card-title>
              <v-card-text>
                <div v-if="redirectAfterPayment">
                  <Loading />
                </div>
                <div v-else-if="!order.items.length">
                  <p class="mt-5">No items.  Please browse to buy tickets.</p>
                  <div class="d-flex">
                    <Button @click='close()'>Close</Button>
                  </div>
                </div>
                <div v-else>
                <v-row v-for="(item, i) in order.items" :key="i">
                  <v-col v-if="item.productId" cols="8">
                    {{item.quantity}} x {{item.product.name}} <span v-if="item.productOption"> / {{item.productOption}}</span>
                    <v-icon class="mb-1 ml-1" small @click="removeItem(i)" style="opacity:0.5">mdi-delete</v-icon>
                  </v-col>
                  <v-col v-else cols="8">
                    {{item.quantity}} x {{item.event.name}} / {{item.ticketType.name}} <span v-if="boxOffice.name != 'sparklejumpropequeen'">/ {{utils.timezoneDateToShortString(item.eventTime.start, item.eventTime.timezone)}}</span> 
                    <v-icon class="mb-1 ml-1" small @click="removeItem(i)" style="opacity:0.5">mdi-delete</v-icon>
                  </v-col>
                  <v-col cols="4">
                    {{utils.numberWithCommas(utils.priceDisplay(calculateValue(item), item.ticketType?.currency || item.product?.currency))}}
                  </v-col>
                </v-row>
                <div v-show="!checkoutNow">
                  <p v-if="multipleCurrencies" class="mt-5 error">Your order contains multiple currencies. Please delete some items until you have one currency selected.</p>
                  <p v-else class="mt-5">You have added to your basket. Please either checkout now, or browse for more tickets.</p>
                  <div class="d-flex">
                    <v-spacer />
                    <Button :disabled="multipleCurrencies" @click='checkoutNow = true'>Checkout</Button>
                  </div>
                </div>
                <div v-show="checkoutNow">
                  <div v-show="!collectingCard">
                    <v-row v-if="allowDonations && isStripeConnected">
                      <v-col cols="8">{{donationPrompt}}</v-col>
                      <v-col cols="4"><v-text-field label="Donation Amount" :prefix="getCurrencyPrefix(orderCurrency)" v-model="donationAmount" :rules="[utils.priceRule]" @keyup="recalculateTotalCost()"></v-text-field></v-col>
                    </v-row>
                    <v-row v-if="cash">
                      <v-col cols="8">Discount</v-col>
                      <v-col cols="4"><v-text-field label="Discount Amount" :prefix="getCurrencyPrefix(orderCurrency)" v-model="discountAmount" :rules="[utils.priceRule]" @keyup="recalculateTotalCost()"></v-text-field></v-col>
                    </v-row>
                    <v-row v-if="discountAmount && !cash">
                      <v-col cols="8">Discount: {{discountCode}} - {{discountPercent}}%</v-col>
                      <v-col cols="4">{{utils.numberWithCommas(utils.priceDisplay(discountAmount, orderCurrency))}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">Total</v-col>
                      <v-col cols="4">{{utils.numberWithCommas(utils.priceDisplay(totalCost, orderCurrency))}}</v-col>
                    </v-row>
                      <v-text-field v-if="boxOffice.hasDiscounts" v-model="discountCode" label="Discount Code" @blur="validateDiscountCode()"></v-text-field>
                      <v-text-field v-model="name" label="Your Name" :rules="[utils.requiredRule]"></v-text-field>
                      <v-text-field v-model="email" label="Email" :rules="[utils.requiredRule, utils.emailRule]"></v-text-field>
                      <v-text-field v-model="confirmEmail" label="Confirm Email" :rules="[utils.requiredRule, utils.emailRule, emailsMatch]"></v-text-field>
                      <v-text-field v-if="collectPhoneNumber=='mandatory' || collectPhoneNumber=='optional'" 
                      v-model="phone" label="Phone" :rules="collectPhoneNumber=='mandatory' ? [utils.requiredRule] : []"></v-text-field>
                      <location v-if="collectLocation" @location="setLocation"/>
                      <v-text-field v-if="collectDateOfBirth" placeholder="DD/MM/YYYY" v-model="dateOfBirth" label="Date Of Birth" :rules="[utils.requiredRule, utils.dateOfBirthRule]"></v-text-field>
                      <v-text-field v-if="collectAddress" v-model="address1" label="Delivery Address" :rules="[utils.requiredRule]"></v-text-field>
                      <v-text-field v-if="collectAddress" v-model="address2" label=""></v-text-field>
                      <v-text-field v-if="collectAddress" v-model="town" label="Town/City" :rules="[utils.requiredRule]"></v-text-field>
                      <v-text-field v-if="collectAddress" v-model="postcode" label="Postcode" :rules="[utils.requiredRule]"></v-text-field>
                      <div v-if="boxOffice && boxOffice.isUserOwner && checkoutNow" class="d-flex align-baseline">
                        <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">Cash Payment?</span>
                        <span v-else class="mr-2">Cash?</span>
                        <v-switch v-model="cash" @change="recalculateTotalCost"/>
                      </div>
                      <div class="d-flex align-baseline">
                        <div class="mr-2">Are you interested in receiving occasional emails about {{boxOffice.name}} and our events?</div>
                        <v-switch class="mt-0" v-model="marketing"/>
                      </div>
                      <v-card-actions v-show="checkoutNow">
                        <v-spacer />
                        <Button :disabled="buyDisabled" :loading="buying" @click='buyTickets'>{{!collectCard ? 'Buy now' : 'Continue'}}</Button>
                      </v-card-actions>
                    </div>
                    <div v-show="collectCard && collectingCard">
                      <div id="payment-element" />
                      <v-card-actions v-show="checkoutNow">
                        <v-spacer />
                        <Button :disabled="buyDisabled" :loading="buying" @click='confirmBuyTickets'>Buy now</Button>
                      </v-card-actions>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>Your Order is Confirmed           
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="mt-4" cols="12" :md="event.thankYouVideo ? 6 : 12">
                    <v-row>
                      <v-col cols="8">Details</v-col>
                      <v-col cols="4">Value</v-col>
                    </v-row>
                    <v-row v-for="(item, i) in orderedItems" :key="i">
                      <v-col v-if="item.productId" cols="8">{{item.quantity}} x {{item.productName}}<span v-if="item.productOption"> / {{item.productOption}}</span></v-col>
                      <v-col v-else cols="8">{{item.quantity}} x {{item.eventName}} / {{item.ticketTypeName}} <span v-if="boxOffice.name != 'sparklejumpropequeen'">/ {{utils.timezoneDateToShortString(item.eventTimeStart, item.eventTimeTimezone)}}</span></v-col>
                      <v-col cols="4">{{utils.numberWithCommas(utils.priceDisplay(item.value, orderCurrency))}}</v-col>
                    </v-row>
                    <v-row v-if="order.donationAmount">
                      <v-col cols="8">Donation</v-col>
                      <v-col cols="4">{{utils.numberWithCommas(utils.priceDisplay(order.donationAmount, orderCurrency))}}</v-col>
                    </v-row>
                    <v-row v-if="order.discountAmount">
                      <v-col cols="8">Discount</v-col>
                      <v-col cols="4">{{utils.numberWithCommas(utils.priceDisplay(order.discountAmount, orderCurrency))}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8"><b>Total</b></v-col>
                      <v-col cols="4"><b>{{utils.numberWithCommas(utils.priceDisplay(totalCost, orderCurrency))}}</b></v-col>
                    </v-row>
                    <v-row class="mt-5">
                      <v-col cols="12">Tickets</v-col>
                    </v-row>
                    <v-row v-for="(ticket) in orderedTickets" :key="ticket.ticketNumber">
                      <v-col cols="12">
                        <span v-if="ticket.showTicketNumber">{{ticket.ticketNumber}} - </span>
                        <span v-else>1 x </span>
                        {{ticket.eventName}} / {{ticket.ticketTypeName}}
                        <span v-if="boxOffice.name != 'sparklejumpropequeen'">/ {{orderedItems.find(f => f.eventTimeId === ticket.eventTimeId) ? utils.timezoneDateToShortString(ticket.eventTimeStart, orderedItems.find(f => f.eventTimeId === ticket.eventTimeId).eventTimeTimezone) : ''}}</span>
                      </v-col>
                    </v-row>
                    <div class="mt-10">Thank you for your order.  
                      <span v-if="boxOffice.name != 'sparklejumpropequeen'">A confirmation email has been sent to {{order.email}}.</span>
                      Your order number is {{order.orderNumber}}</div>
                  </v-col>
                  <v-col v-if="event.thankYouVideo" cols="12" md="6">
                    <v-card-text>
                    <video controls>
                      <source :src="event.thankYouVideo">
                    </video>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <Button class="mb-4" @click='closeConfirmed()'>Close</Button>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </v-dialog>
  </div>
  </template>
    
  <script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js'
  import Store from '@/services/Store.js';
  import { getCurrencyPrefix } from '@/helpers/currency'
  import { loadStripe } from '@stripe/stripe-js'
  
    export default {
      components: {
        Location: () => import('./Location'),
        Button: () => import('./Button'),
        PagesHeading: () => import('../pages/components/Heading'),
        PrivacyPolicy: () => import('./PrivacyPolicy'),
        EventTimeDisplay: () => import('../dashboard/components/EventTimeDisplay'),
        Loading: () => import('./Loading'),
      },  
      
      props: {
        boxOffice: null,
        event: null,
      },
  
      data () {
        return {
          orderCurrency: null,
          multipleCurrencies: false,
          getCurrencyPrefix,
          show: true,
          checkoutNow: false,
          events: [],
          stripeLoaded: false,
          buying: false,
          collectingCard: false,
          totalCost: 0.00,
          cash: false,
          stripe: null,
          elements: null,
          stripeClientSecret: null,
          complete: false,
          confirmDialog: false,
          donationAmount: 0,
          discountAmount: 0,
          secretId: "",
          name: "",
          email: "",
          confirmEmail: "",
          phone: "",
          message: "",
          redirectAfterPayment: false,
          marketing: false,
          order: Store.order,
          orderedTickets: [],
          orderedItems: [],
          ticketTypes: null,
          totalQuantity: 0,
          address1: null,
          address2: null,
          town: null,
          postcode: null,
          location: null,
          dateOfBirth: null,
          discountCode: null,
          discountPercent: 0,
        }
      },
  
      created() {
        const { secretId, redirect_status } = this.$route.query
        if (redirect_status === 'succeeded' && secretId) {
          this.redirectAfterPayment = true
          this.secretId = secretId
          this.$router.replace({ path: this.$route.path, query: {} });
          this.waitForComplete()
        }
        
        this.stripeLoaded = false;
        this.$vuetify.theme.dark = false;
        this.load();
      },
  
      methods: {
  
        setLocation(location) {
          this.location = location
        },
  
        emailsMatch(value) {
          if (value == this.email) return true;
          return 'Emails must match.';
        },
  
        close() {
          this.confirmDialog = false;
          this.$emit('closed');
        },
  
        closeConfirmed() {
          this.confirmDialog = false;
          this.$emit('closedConfirmed');
        },
  
        async load() {
          this.loadStripe();
          this.recalculateTotalCost();
        },
  
        async validateDiscountCode() {
          this.discountPercent = 0
          if (this.discountCode) {
            let result = await Api.post(this, "BoxOffice", "validateDiscountCode", {boxOfficeId:this.boxOffice.id, discountCode:this.discountCode}, true);
            if (result && result.discount) {
              this.discountPercent = result.discount
            } else {
              this.$toast.error(" ", "invalid discount code", {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
            }
          }
          this.recalculateTotalCost();
        },
  
        plural(number) {
          if (number > 1) {
            return "s";
          }
        },
  
        async buyTickets() {
          if (! await Utils.validate(this.$refs.form) ) {
            return;
          }
          this.buying = true;
          this.order.boxOfficeId = this.boxOffice.id;
          this.order.donationAmount = this.donationAmount;
          if (!this.order.donationAmount) {
            this.order.donationAmount = 0;
          }
          if (this.cash) {
            this.order.cash = true;
            this.order.discountAmount = this.discountAmount;
          }
          this.order.name = this.name;
          this.order.email = this.email;
          this.order.discountCode = this.discountCode;
          this.order.phone = this.phone;
          this.order.address1 = this.address1;
          this.order.address2 = this.address2;
          this.order.town = this.town;
          this.order.postcode = this.postcode;
          this.order.marketing = this.marketing;
          this.order.location = JSON.stringify(this.location);
          this.order.dateOfBirth = this.dateOfBirth;
  
          // Get the tag
          const currentUrl = window.location.href;
          const urlObj = new URL(currentUrl)
          const tagValue = urlObj.searchParams.get('tag')
          if (tagValue) {
            this.order.tag = tagValue
          }
  
          let newOrder = {}
          try {
            newOrder = await Api.post(this, "Order", "create", this.order);
          } catch (error) {
            // probably not enough tickets - so reset amounts to what is available
            try {
              for (let item of this.order.items) {
                if (!item.productId) {
                  let availabilities = await Api.post(this, "EventTime", "availability", item.eventTime);
                  for (let availability of availabilities) {
                    if (availability.ticketTypeId == item.ticketType.id) {
                      if (item.quantity > availability.availableTickets) {
                        item.quantity = availability.availableTickets;
                      }
                    }
                  }
                }
              }
              // remove any tickets that are no longer available
              for (let i=0; i<this.order.items.length; i++) {
                if (!this.order.items[i].quantity) {
                  this.removeItem(i)
                }
              }
            } catch (error2) {
              // don't throw this one, its just a rollback error
              this.buying = false;
              console.error(error2);
            }
            this.recalculateTotalCost();
            this.buying = false;
            throw error;
          }
          // save the secret - we only get it on create()
          this.secretId = newOrder.secretId;
          this.stripeClientSecret = newOrder.clientSecret
          if (newOrder.clientSecret) {
            this.collectingCard = true
            this.buying = false
            this.handleCardPayment();
          } else {
            this.waitForComplete();
          }
        },
  
        async handleCardPayment() {
          const options = {
            clientSecret: this.stripeClientSecret,
            layout: {
              type: 'tabs',
              defaultCollapsed: false,
            }
          }
          this.elements = this.stripe.elements(options)
          this.paymentElement = this.elements.create('payment')
          this.paymentElement.mount('#payment-element')
        },

        async confirmBuyTickets () {
          this.buying = true

          const paymentResponse = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: { return_url: `${location.href}?secretId=${this.secretId}` },
            redirect: 'if_required',
          })
          const { error, paymentIntent: { id: paymentIntent } = {} } = paymentResponse || {}
          if (error) {
            console.log('error')
            this.buying = false
          } else {
            this.waitForComplete();
          }
        },
  
        async waitForComplete() {
          var confirmed = false;
          var confirmedOrder = {};
          while (!confirmed) {
            await new Promise(r => setTimeout(r, 1000));
            confirmedOrder = await Api.post(this, "Order", "findBySecretId", {secretId: this.secretId});
            confirmed = confirmedOrder.status == "confirmed";
          }
          Store.resetItems();
          this.order = confirmedOrder;
          this.orderedTickets = await Api.post(this, "Ticket", "listBySecretId", {secretId: this.secretId});
          this.orderedItems = await Api.post(this, "OrderItem", "listBySecretId", {secretId: this.secretId});
          this.buying = false;
          this.confirmDialog = true;
        },
  
        recalculateTotalCost() {
          this.totalCost = 0;
          this.totalQuantity = 0;
          let currency = ''
          this.multipleCurrencies = false
          for (let item of this.order.items) {
            this.totalQuantity += item.quantity
            this.totalCost += this.calculateValue(item)
            const itemCurrency = item.ticketType?.currency || item.product?.currency
            if (currency && currency !== itemCurrency) {
              this.multipleCurrencies = true
            }
            else {
              this.orderCurrency = itemCurrency
              currency = itemCurrency
            }
          }
          this.totalCost += Utils.priceToNumber(this.donationAmount);
          if (this.cash) {
            this.totalCost -= Utils.priceToNumber(this.discountAmount);
          }
          this.discountAmount = this.totalCost * this.discountPercent/100
          this.totalCost -= this.discountAmount
          this.orderCurrency = currency || this.boxOffice.currency
        },
  
        calculateValue(item) {
          if (item.productId) {
            return item.quantity * item.product.price
          } else {
            return item.quantity * (item.ticketType.price + item.ticketType.bookingFee)
          }
        },
  
        findOrderItem (ticketType, eventTime) {
          if (this.order && this.order.items) {
            for (let item of this.order.items) {
              if (item.ticketTypeId == ticketType.id && item.eventTimeId == eventTime.id)  {
                return item;
              }
            }
          }
        },
  
        async removeItem(i) {
          this.order.items.splice(i, 1);
          this.recalculateTotalCost();
        },
  
        async loadStripe () {
          if (!this.stripeLoaded) {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY, {stripeAccount: this.boxOffice.stripeId});
            // this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY, {stripeAccount: this.boxOffice.stripeId});
            // this.elements = this.stripe.elements();
            
            // document.getElementById('cardNumber-errors').innerHTML = '&nbsp;';
            // document.getElementById('cardExpiry-errors').innerHTML = '&nbsp;';
            // document.getElementById('cardCvc-errors').innerHTML = '&nbsp;';
            // var cardNumber = this.elements.create('cardNumber', this.stripeOptions("Card Number"));
            // cardNumber.mount(this.$refs.cardNumber);
            
            // cardNumber.addEventListener('change', (event) => {
            //   const displayError = document.getElementById('cardNumber-errors');
            //   displayError.innerHTML = "&nbsp;";
            //   this.cardNumberComplete = false;
            //   if (event.complete) {
            //     this.cardNumberComplete = true;
            //   } else if (event.error) {
            //     displayError.innerHTML = event.error.message;
            //   }
            // });
  
            // var cardExpiry = this.elements.create('cardExpiry', this.stripeOptions("Card Expiry"));
            // cardExpiry.mount(this.$refs.cardExpiry);
            // cardExpiry.addEventListener('change', (event) => {
            //   const displayError = document.getElementById('cardExpiry-errors');
            //   displayError.innerHTML = '&nbsp;';
            //   this.cardExpiryComplete = false;
            //   if (event.complete) {
            //     this.cardExpiryComplete = true;
            //   } else if (event.error) {
            //     displayError.innerHTML = event.error.message;
            //   }
            // });
  
            // var cardCvc = this.elements.create('cardCvc', this.stripeOptions("CVC Number"));
            // cardCvc.mount(this.$refs.cardCvc);
            // cardCvc.addEventListener('change', (event) => {
            //   const displayError = document.getElementById('cardCvc-errors');
            //   displayError.innerHTML = '&nbsp;';
            //   this.cardCcvComplete = false;
            //   if (event.complete) {
            //     this.cardCcvComplete = true;
            //   } else if (event.error) {
            //     displayError.innerHTML = event.error.message;
            //   }
            // });
            this.stripeLoaded = true;
          }
        },
  
        stripeOptions(placeholder) {
          var stripeOptions = {};
          stripeOptions.style = {};
          stripeOptions.placeholder = placeholder;
          return stripeOptions;
        },
      },
  
      computed: {
        utils: function () {
          return Utils;
        },
  
        buyDisabled: function () {
          if (!this.totalQuantity) {
            return true;
          }
          return this.collectCard && !(this.name && this.email && this.confirmEmail);
        },
  
        collectCard() {
          if (this.cash) {
            return false;
          }
          if (this.totalCost == 0) {
            return false;
          }
          return true;
        },
  
        allowDonations() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.allowDonations) {
                return true;
              }
            }
          }
          return false;
        },

        isStripeConnected() {
          return this.boxOffice.stripeId ? true : false;
        },
  
        collectLocation() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.collectLocation) {
                return true;
              }
            }
          }
          return false;
        },
  
        collectPhoneNumber() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.collectPhoneNumber) {
                return item.event.collectPhoneNumber;
              }
            }
          }
          return "";
        },
  
        collectDateOfBirth() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.collectDateOfBirth) {
                return true;
              }
            }
          }
          return false;
        },
  
        collectAddress() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.productId && item.collectAddress) {
                return true;
              }
            }
          }
          return false;
        },
  
        donationPrompt() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.donationPrompt) {
                return item.event.donationPrompt;
              }
            }
          }
          return ""
        },
  
        textColor() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.textColour) {
                return item.event.textColour;
              }
            }
          }
          return this.boxOffice.textColour
        },
  
        backgroundColour() {
          if (this.order.items) {
            for (let item of this.order.items) {
              if (item.event && item.event.backgroundColour) {
                return item.event.backgroundColour;
              }
            }
          }
          return this.boxOffice.backgroundColour
        },
  
      },
    }
  </script>
  
  <style lang="scss" scoped>
  .v-dialog {
    border-radius: 0px;
  }
  .dialog-inner {
    position: relative;
  }
  .v-sheet.v-card {
    border: thick solid;
    border-radius: 0px;
  }
  video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 60vh;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
  </style>
  